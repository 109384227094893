import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    useCreateBannerMutation,
    useEditBannerMutation,
    useFindBannerByIdQuery,
} from "../../api/grailzApi";
import {
    initialBannerState,
    selectBannerState,
    setBannerData,
    setBannerProperty,
} from "../../slices/bannersSlice";
import {routes} from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import UploadImageInput from "../../components/form/UploadImageInput";
import {recognizeLabel} from "../../utils/bannerLabelRecognizer";

const types = [
    {label: recognizeLabel("AFFILIATE_1"), value: "AFFILIATE_1"},
    {label: recognizeLabel("AFFILIATE_1_2"), value: "AFFILIATE_1_2"},
    {label: recognizeLabel("AFFILIATE_2"), value: "AFFILIATE_2"},
    {label: recognizeLabel("AFFILIATE_2_2"), value: "AFFILIATE_2_2"},
    {label: recognizeLabel("AFFILIATE_3"), value: "AFFILIATE_3"},
    {label: recognizeLabel("AFFILIATE_3_2"), value: "AFFILIATE_3_2"},
    {label: recognizeLabel("AFFILIATE_3_3"), value: "AFFILIATE_3_3"},
    {label: recognizeLabel("AFFILIATE_3_4"), value: "AFFILIATE_3_4"},
    {label: recognizeLabel("AFFILIATE_3_5"), value: "AFFILIATE_3_5"},
    {label: recognizeLabel("AFFILIATE_7"), value: "AFFILIATE_7"},
    {label: recognizeLabel("AFFILIATE_7_2"), value: "AFFILIATE_7_2"},
    {label: recognizeLabel("AFFILIATE_7_3"), value: "AFFILIATE_7_3"},
    {label: recognizeLabel("AFFILIATE_7_4"), value: "AFFILIATE_7_4"},
    {label: recognizeLabel("AFFILIATE_7_5"), value: "AFFILIATE_7_5"},
    {label: recognizeLabel("AFFILIATE_8"), value: "AFFILIATE_8"},
    {label: recognizeLabel("AFFILIATE_8_2"), value: "AFFILIATE_8_2"},
    {label: recognizeLabel("AFFILIATE_8_3"), value: "AFFILIATE_8_3"},
    {label: recognizeLabel("AFFILIATE_8_4"), value: "AFFILIATE_8_4"},
    {label: recognizeLabel("AFFILIATE_8_5"), value: "AFFILIATE_8_5"},
    {label: recognizeLabel("AFFILIATE_8_6"), value: "AFFILIATE_8_6"},
    {label: recognizeLabel("AFFILIATE_8_7"), value: "AFFILIATE_8_7"},
    {label: recognizeLabel("AFFILIATE_8_8"), value: "AFFILIATE_8_8"},
    {label: recognizeLabel("AFFILIATE_8_9"), value: "AFFILIATE_8_9"},
    {label: recognizeLabel("AFFILIATE_8_10"), value: "AFFILIATE_8_10"},
    {label: recognizeLabel("AFFILIATE_8_11"), value: "AFFILIATE_8_11"},
    {label: recognizeLabel("AFFILIATE_8_12"), value: "AFFILIATE_8_12"},
    {label: recognizeLabel("AFFILIATE_8_13"), value: "AFFILIATE_8_13"},
    {label: recognizeLabel("AFFILIATE_8_14"), value: "AFFILIATE_8_14"},
    {label: recognizeLabel("AFFILIATE_8_15"), value: "AFFILIATE_8_15"},
    {label: recognizeLabel("AFFILIATE_9"), value: "AFFILIATE_9"},
    {label: recognizeLabel("AFFILIATE_9_2"), value: "AFFILIATE_9_2"},
    {label: recognizeLabel("AFFILIATE_9_3"), value: "AFFILIATE_9_3"},
    {label: recognizeLabel("AFFILIATE_9_4"), value: "AFFILIATE_9_4"},
    {label: recognizeLabel("AFFILIATE_9_5"), value: "AFFILIATE_9_5"},
    {label: recognizeLabel("BARGAIN_1"), value: "BARGAIN_1"},
    {label: recognizeLabel("BARGAIN_4"), value: "BARGAIN_4"},
    {label: recognizeLabel("BARGAIN_2"), value: "BARGAIN_2"},
    {label: recognizeLabel("BARGAIN_2_2"), value: "BARGAIN_2_2"},
    {label: recognizeLabel("BARGAIN_2_3"), value: "BARGAIN_2_3"},
    {label: recognizeLabel("BARGAIN_2_4"), value: "BARGAIN_2_4"},
    {label: recognizeLabel("BARGAIN_2_5"), value: "BARGAIN_2_5"},
    {label: recognizeLabel("BARGAIN_3"), value: "BARGAIN_3"},
    {label: recognizeLabel("BARGAIN_3_2"), value: "BARGAIN_3_2"},
    {label: recognizeLabel("BARGAIN_3_3"), value: "BARGAIN_3_3"},
    {label: recognizeLabel("BARGAIN_3_4"), value: "BARGAIN_3_4"},
    {label: recognizeLabel("BARGAIN_3_5"), value: "BARGAIN_3_5"},
    {label: recognizeLabel("BARGAIN_3_6"), value: "BARGAIN_3_6"},
    {label: recognizeLabel("BARGAIN_3_7"), value: "BARGAIN_3_7"},
    {label: recognizeLabel("BARGAIN_3_8"), value: "BARGAIN_3_8"},
    {label: recognizeLabel("BARGAIN_3_9"), value: "BARGAIN_3_9"},
    {label: recognizeLabel("BARGAIN_3_10"), value: "BARGAIN_3_10"},
    {label: recognizeLabel("BARGAIN_7"), value: "BARGAIN_7"},
    {label: recognizeLabel("BARGAIN_7_2"), value: "BARGAIN_7_2"},
    {label: recognizeLabel("BARGAIN_7_3"), value: "BARGAIN_7_3"},
    {label: recognizeLabel("BARGAIN_7_4"), value: "BARGAIN_7_4"},
    {label: recognizeLabel("BARGAIN_7_5"), value: "BARGAIN_7_5"},
    {label: recognizeLabel("BARGAIN_8"), value: "BARGAIN_8"},
    {label: recognizeLabel("BARGAIN_8_2"), value: "BARGAIN_8_2"},
    {label: recognizeLabel("BARGAIN_8_3"), value: "BARGAIN_8_3"},
    {label: recognizeLabel("BARGAIN_8_4"), value: "BARGAIN_8_4"},
    {label: recognizeLabel("BARGAIN_8_5"), value: "BARGAIN_8_5"},
    {label: recognizeLabel("BARGAIN_9"), value: "BARGAIN_9"},
    {label: recognizeLabel("BARGAIN_9_2"), value: "BARGAIN_9_2"},
    {label: recognizeLabel("BARGAIN_9_3"), value: "BARGAIN_9_3"},
    {label: recognizeLabel("BARGAIN_9_4"), value: "BARGAIN_9_4"},
    {label: recognizeLabel("BARGAIN_9_5"), value: "BARGAIN_9_5"},
    {label: recognizeLabel("GRAIL_SHOP_1"), value: "GRAIL_SHOP_1"},
    {label: recognizeLabel("GRAIL_SHOP_2"), value: "GRAIL_SHOP_2"},
    {label: recognizeLabel("GRAIL_SHOP_3"), value: "GRAIL_SHOP_3"},
    {label: recognizeLabel("GRAIL_SHOP_4"), value: "GRAIL_SHOP_4"},
    {label: recognizeLabel("GRAIL_SHOP_5"), value: "GRAIL_SHOP_5"},
    {label: recognizeLabel("GRAIL_SHOP_6"), value: "GRAIL_SHOP_6"},
];

const enabledOptions = [
    {value: true, label: "Tak"},
    {value: false, label: "Nie"},
];

const UpdateBanner: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindBannerByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateBanner,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useEditBannerMutation();
    const [
        createBanner,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateBannerMutation();

    const {banner, genderType} = useAppSelector(selectBannerState);
    const {name, type, imageUrl, referralLink, price, discountedPrice, priority, title, subtitle, enabled, shopCategorySlug, shopProductId} = banner;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setBannerData({property: "banner", value: initialBannerState}));

        if (!id) {
            dispatch(
                setBannerProperty({property: "genderType", value: genderType})
            );
        }

        if (isSuccess) {
            navigate(`/${routes.BANNERS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateBanner({id: id, bannerRequest: banner})
            : createBanner({bannerRequest: banner});
    };

    const genderTypeLabel = () => {
        switch (genderType) {
            case "DEFAULT":
                return "baner afiliacyjny";
            case "MALE":
                return "promocję męską";
            case "FEMALE":
                return "promocje damską";
            case "GRAIL_POINT":
                return "baner sklepu";
        }
    }

    const filterTypes = () => {
        switch (genderType) {
            case "DEFAULT":
                return types.filter(type => type.value.startsWith("AF"));
            case "MALE":
            case "FEMALE":
                return types.filter(type => type.value.startsWith("BAR"));
            case "GRAIL_POINT":
                return types.filter(type => type.value.startsWith("GRAIL"));
            default:
                return [];
        }
    }

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Dodaj ${genderTypeLabel()}`
                        : `Aktualizuj ${genderTypeLabel()} ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setBannerProperty}
                />
                <SelectInput
                    id="type"
                    options={filterTypes()}
                    name="type"
                    value={type}
                    label="Typ"
                    setProperty={setBannerProperty}
                />
                <SelectInput
                    id="enabled"
                    options={enabledOptions}
                    name="enabled"
                    value={enabled}
                    label="Widoczne w aplikacji"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="referralLink"
                    name="referralLink"
                    value={referralLink}
                    label="Link referencyjny"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="price"
                    name="price"
                    value={price}
                    label="Cena"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="discountedPrice"
                    name="discountedPrice"
                    value={discountedPrice}
                    label="Cena przed przeceną"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="priority"
                    name="priority"
                    value={priority}
                    label="Priorytet (określa miejsce na feedzie, czym wyższy, tym wyżej będzie umiejscowiony)"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="title"
                    name="title"
                    value={title}
                    label="Tytuł"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="subtitle"
                    name="subtitle"
                    value={subtitle}
                    label="Krótki opis"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="shopCategorySlug"
                    name="shopCategorySlug"
                    value={shopCategorySlug}
                    label="Slug do kategorii w sklepie, np. buty-nike"
                    setProperty={setBannerProperty}
                />
                <TextInput
                    id="shopProductId"
                    name="shopProductId"
                    value={shopProductId}
                    label="ID do produktu w sklepie np. 283740"
                    setProperty={setBannerProperty}
                />
                <UploadImageInput
                    id="imageUrl"
                    name="imageUrl"
                    value={imageUrl}
                    label="Zdjęcie baneru"
                    setProperty={setBannerProperty}
                />
                <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </>
    );
};

export default UpdateBanner;
